


















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class Edit extends Vue {
  public $refs: Vue['$refs'] & {
    saveForm: {
      validate: any
      reset: any
    }
  }

  private loading = true
  private saveIsLoading = false
  private firstTimeLoading = true
  private useRewrittenTyreHotelView = false

  private data = {
    reading: null,
    updateTyreHotelTreadDepths: false,
    updateTyreHotelTyreData: false,
    updateLastTyreHotelCertificate: false,
    updateOption: 'READING_SEASON',
  }

  private updateOptions = [
    {
      value: 'READING_SEASON',
      text: this.$t('c:tread-depth-reader:Based on the reading season'),
    },
    {
      value: 'WHEEL_SET_ON_CAR',
      text: this.$t('c:tread-depth-reader:Wheel set that is on car'),
    },
    {
      value: 'WHEEL_SET_IN_HOTEL',
      text: this.$t('c:tread-depth-reader:Wheel set that is in hotel'),
    },
  ]

  private seasons = []

  private tyreHotels = []

  private cars = []
  private carSearchLoading = false
  private carSearch = ''

  private rules = {
    season: [],
  }

  private appendSitePrefix = appendSitePrefix

  private created() {
    this.rules = {
      season: [
        (v) =>
          !this.willUpdateTyreHotel() ||
          (!!v && this.data.updateOption === 'READING_SEASON') ||
          this.$t(
            'c:tread-depth-reader:When updating tyre hotel from the reading season, a season must be set for this reading',
          ),
      ],
    }

    this.loading = true

    this.$axios
      .get('/v4/site/tread-depth-readings/edit/initial-data')
      .then((response) => {
        this.seasons = response.data.seasons
        this.useRewrittenTyreHotelView = response.data.useRewrittenTyreHotelView

        this.$axios
          .get('/v4/site/tread-depth-readings/' + this.$route.params.id)
          .then((response) => {
            const reading = response.data.data

            if (reading.car) {
              const selectedCar = { id: reading.car.id, licenseplate: reading.car.licenseplate }
              this.cars = [selectedCar]
            }

            if (reading.tyreHotelId) {
              this.tyreHotels = [reading.tyreHotelId]
            }

            this.data.reading = reading
          })
          .catch((err) => {
            err.response.data.errors.forEach((v) => {
              vxm.alert.error({
                content: v.message as string,
                title: this.$t('c:common:Error') as string,
              })
            })
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }

  private getBack() {
    this.$router.back()
  }

  private save() {
    if (this.$refs.saveForm.validate()) {
      this.saveIsLoading = true

      this.$axios
        .put('/v4/site/tread-depth-readings/' + this.$route.params.id, this.data)
        .then(() => {
          this.$router.push({ name: 'Car/TreadDepthReading/Show', params: { id: this.$route.params.id } })
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error on saving')
        })
        .finally(() => {
          this.saveIsLoading = false
        })
    }
  }

  private get updateTyreHotelTreadDepthsLabel() {
    return (
      this.$t('c:tread-depth:On save update tyre tread depths of tyre hotel') + ' ' + this.data.reading.tyreHotelId
    )
  }

  private get updateTyreHotelTyreDataLabel() {
    return this.$t('c:tread-depth:On save update tyre data of tyre hotel') + ' ' + this.data.reading.tyreHotelId
  }

  private get updateLastTyreHotelCertificateLabel() {
    return (
      this.$t('c:tread-depth:Update last tyre hotel certificate with these changes for tyre hotel') +
      ' ' +
      this.data.reading.tyreHotelId
    )
  }

  @Watch('data.reading.carId', { immediate: true })
  private onCarChanged() {
    if (this.data.reading.carId !== null) {
      let url = ''
      if (this.useRewrittenTyreHotelView) {
        url = '/v4/site/tyre-hotels-v2/get-by-car/' + this.data.reading.carId
      } else {
        url = '/v4/site/tyre-hotels/get-by-car/' + this.data.reading.carId
      }

      this.$axios
        .get(url)
        .then((response) => {
          this.tyreHotels = response.data.data
        })
        .catch((err) => {
          err.response.data.errors.forEach((v) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  @Watch('carSearch')
  @Debounce(300)
  private onCarSearchChanged(val) {
    if (this.firstTimeLoading || val === null || val.length < 2) {
      this.firstTimeLoading = false
      return
    }

    this.carSearchLoading = true
    this.$axios
      .get('/v4/site/cars/search?licensePlate=' + val)
      .then((response) => {
        this.cars = response.data.data
      })
      .finally(() => {
        this.carSearchLoading = false
      })
  }

  private willUpdateTyreHotel(): boolean {
    return this.data.updateTyreHotelTreadDepths || this.data.updateTyreHotelTyreData
  }
}
